@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ffffff 1.84%, #ffffff 102.67%);
  --gradient-bar: linear-gradient(103.22deg, rgb(255, 255, 255) -13.86%, #ffffff 99.55%);
  
  --color-bg: #f21743;
  --color-footer : #1157a7;
  --color-blog: #d7289a;
  --color-text: #ffffff;
  --color-subtext: #e73c15;
}
